import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { FiBell, FiHeart, FiHome } from 'react-icons/fi'; // これらのアイコンを例として使用します
import { Link } from 'react-router-dom';

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import "./App.css"
import { useNavigate,useLocation } from 'react-router-dom';
import { ChakraProvider} from '@chakra-ui/react';

import { Wrap, Text, IconButton, Button, Input, Box,  Modal, ModalOverlay, ModalContent,  ModalCloseButton, ModalBody, ModalHeader,ModalFooter, useDisclosure, Switch, FormLabel, FormControl, Tag,  VStack, useColorMode, Flex, List, ListItem,  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Card,
  CardHeader,
  Heading,
  CardBody,
  Stack,
  StackDivider } from '@chakra-ui/react';


function ImageGallery() {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [taxonomies, setTaxonomies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreImages, setHasMoreImages] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestedTerms, setSuggestedTerms] = useState([]);
  const [isNsfw, setIsNsfw] = useState(false);
  const [page, setPage] = useState(1);
  const { colorMode, toggleColorMode } = useColorMode();
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [currentSearchQuery, setCurrentSearchQuery] = useState("");
  const [stateNsfw, setStateNsfw] = useState("");
  const [hasAgreed, setHasAgreed] = useState(false);
  const [isFav, setFav] = useState(false);
  const [isFirstVisit, setIsFirstVisit] = useState(true);
  const [likedImages, setLikedImages] = useState([]);
  const {isOpen, onOpen, onClose } = useDisclosure();
  const drawerDisclosure = useDisclosure();
  const [posts, setPosts] = useState([]);

  const inputRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  function isExternalLink(url) {
    const currentDomain = window.location.origin;
  
    return url.startsWith('http') && !url.startsWith(currentDomain);
  }

  const fetchPosts = async () => {
    try {
      const response = await fetch('https://api.iinano.net/wp-json/wp/v2/topic?per_page=3&_embed');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleLikeButton = (image) => {
    const imageToToggle = { id: image.id, filename: image.url.split('/').pop(), url: image.url, nsfw: image.nsfw };
    let updatedLikedImages;
  
    if (likedImages.some(img => img.id === image.id)) {
      // いいねを解除
      updatedLikedImages = likedImages.filter(img => img.id !== image.id);
    } else {
      // いいねを追加
      updatedLikedImages = [...likedImages, imageToToggle];
    }
  
    setLikedImages(updatedLikedImages);
    localStorage.setItem('likedImages', JSON.stringify(updatedLikedImages));
  };
  
  // ハートアイコンの色を切り替えるための関数
  const isLiked = (image) => {
    if (image && image.id) {
      return likedImages.some(img => img.id === image.id);
    }
  };
  
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const handleInputChange = (e) => {
    setCurrentSearchQuery(e.target.value);
    const lastWord = e.target.value.split(" ").slice(-1)[0];
    if (!lastWord) {
      setSuggestedTerms([]);
      return;
    }
    
    const matchedTerms = JSON.parse(localStorage.getItem("terms"))
      .filter(term => term.name.includes(lastWord) || term.slug.includes(lastWord));
    setSuggestedTerms(matchedTerms);
  };

  // タームをクリックしたときに実行されるハンドラー
  const handleTermClick = (term) => {
    const words = currentSearchQuery.split(" ");
    words.pop();
    words.push(term.name);
    setCurrentSearchQuery(words.join(" ") + " ");
    setSuggestedTerms([]);
    inputRef.current.focus(); // 入力欄にフォーカスを戻す
  };
  
  // 検索フォームの送信ハンドラー
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const newQuery = e.target[0].value;
    if(currentSearchQuery !== searchQuery) {
      setHasMoreImages(true);
      setImages([]);
      setPage(1);
    } else {
      setHasMoreImages(true);
      setPage(0);
    }
    setSearchQuery(newQuery);

    // fetchImages(1, searchQuery); // 検索クエリに基づいて画像を取得
  };

  const handleTaxonomyClick = (taxonomy) => {
    setSearchQuery(taxonomy.name);
    setCurrentSearchQuery(taxonomy.name);
    setHasMoreImages(true);
    setImages([]);
    setPage(1);
    closeModal();
  };


  const fetchImage = (imageId) => {
    fetch(`https://api.iinano.net/wp-json/wp/v2/media/${imageId}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(imageData => {
        // imageDataには取得した画像の詳細情報が含まれています
        // 必要なデータを抽出し、setStateなどで保存し、モーダルを表示する
        let viewImage = {
          "url":imageData.source_url,
          "id":imageData.id
        };
        
        openModal(viewImage);
        // その他のロジック（モーダルを開くなど）
      })
      .catch(error => console.error('Error fetching image:', error));
  };


  useEffect(() => {
    // ダークモードをデフォルトで有効にする
    if (colorMode === 'light') {
      toggleColorMode();
    }
  }, [colorMode, toggleColorMode]);


  useEffect(() => {
    const userAgreement = localStorage.getItem('userAgreed');
    if (userAgreement) {
      setHasAgreed(true);

      const pathParts = window.location.pathname.split('/').filter(part => part);
      if (pathParts.length === 2) {
        const [imageId, imageName] = pathParts;
        fetchImage(imageId, imageName);
      }
    }
    setIsFirstVisit(false);
    if(!hasAgreed) return;

    const fetchAllTerms = async () => {
      let allTerms = [];
      let perPage = 100;
      let page = 1;
      let hasMore = true;
  
      while (hasMore) {
        const response = await fetch(`https://api.iinano.net/wp-json/wp/v2/tax_media_tag?per_page=${perPage}&page=${page}`);
        const terms = await response.json();
        allTerms = allTerms.concat(terms);
        hasMore = terms.length === perPage;
        page++;
      }
  
      localStorage.setItem("terms", JSON.stringify(allTerms));
      localStorage.setItem("lastFetchTime", new Date().getTime());
    };
  
    const storedTerms = localStorage.getItem("terms");
    const lastFetchTime = localStorage.getItem("lastFetchTime");
    const currentTime = new Date().getTime();
  
    if (!storedTerms || !lastFetchTime || currentTime - lastFetchTime >= 24 * 60 * 60 * 1000) {
      fetchAllTerms();
    }


    const storedNsfw = localStorage.getItem('nsfw');
    if (storedNsfw) {
      setIsNsfw(storedNsfw === 'true');
    }

    // LocalStorageからいいねされた画像のデータを読み込む
    const storedLikedImages = JSON.parse(localStorage.getItem('likedImages')) || [];
    setLikedImages(storedLikedImages);

    fetchPosts().then(data => {
      setPosts(data);
    });
  }, [hasAgreed]);

  useEffect(() => {

    const fetchImages = () => {
      setIsLoading(true);
      const pathParts = window.location.pathname.split('/').filter(part => part);
      const storedNsfw = localStorage.getItem('nsfw');

      // URLが/fav/の場合、お気に入り画像リストを表示する
      if (pathParts[0] === 'fav') {
        setImages([]);
        setPage(1);
        setFav(true);
        // LocalStorageからお気に入り画像データを取得
        let storedLikedImages = JSON.parse(localStorage.getItem('likedImages')) || [];

        if (storedNsfw !== "true") {
          storedLikedImages = storedLikedImages.filter(img => img.nsfw !== "1");
        }

        const imagesPerPage = 10;
        const startIndex = (page - 1) * imagesPerPage;
        const newImages = storedLikedImages.slice(startIndex, startIndex + imagesPerPage);
      
        if(newImages.length > 0) {
          setImages(prevImages => {
            // 重複を避けるために、新しい画像が既存のリストに含まれていないか確認
            const updatedImages = newImages.filter(newImage => 
              !prevImages.some(prevImage => prevImage.id === newImage.id));
            return [...prevImages, ...updatedImages];
          });
        } else {
          setHasMoreImages(false);
        }
        setIsLoading(false);

      } else if(!isOpen) {
        setFav(false);
        
        let url = `https://api.iinano.net/wp-json/mycustom/v1/media/?page=${page}&posts_per_page=20`;
        if (searchQuery) {
          const terms = searchQuery.trim().split(" ").filter(t => t);
          url += `&tax_media_tag[]=${terms.join('&tax_media_tag[]=')}`;
        }
        if (storedNsfw === "true") {
          url += "&nsfw=true"; // NSFWがONの場合、クエリに追加
        } else {
          url += "&nsfw=false";
        }
        // console.log(url);
        fetch(url, {
          method: 'GET',
          mode: 'cors',
      })
          .then(response => {
            if (!response.ok) throw new Error('No more images');
            return response.json();
          })
          .then(newImages => {
            if (newImages.length > 0) {
              const shuffledImages = shuffleArray(newImages);
    
              setImages(prevImages => {
                // 重複を避けるために、新しい画像が既存のリストに含まれていないか確認
                const updatedImages = shuffledImages.filter(newImage => 
                  !prevImages.some(prevImage => prevImage.id === newImage.id));
                return [...prevImages, ...updatedImages];
              });
            } else {
              setHasMoreImages(false); // 新しい画像がない場合はフラグを更新
            }
            setIsLoading(false);
          })
          .catch(error => {
            console.error('Error fetching images:', error);
            setIsLoading(false);
          });
      }

    };
    
      fetchImages();
  }, [page,isNsfw,isFav,searchQuery,hasMoreImages,location]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop < document.documentElement.offsetHeight || isLoading || !hasMoreImages) return;
      setPage(prevPage => prevPage + 1);
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading,hasMoreImages]);

  // トグルボタンの変更をハンドルする関数
  const handleNsfwToggle = () => {
    const newIsNsfw = !isNsfw;
    setHasMoreImages(true);
    setImages([]);
    setPage(1);
    setIsNsfw(newIsNsfw);
    setStateNsfw(stateNsfw);
    localStorage.setItem('nsfw', newIsNsfw ? 'true' : 'false'); // LocalStorageに保存
  };


  const openModal = (image,index) => {

    const imageName = image.url.split('/').pop().split('.')[0];
    navigate(`/${image.id}/${imageName}`);

    setSelectedImage(image);
    onOpen();


    // カスタムエンドポイントを使用してタクソノミーの情報を取得
    fetch(`https://api.iinano.net/wp-json/mycustom/v1/media_taxonomy/${image.id}`)
      .then(response => response.json())
      .then(taxonomies => {

        // 取得したタクソノミー情報をセット
        setTaxonomies(taxonomies);
      setCurrentImageIndex(index);

      })
      .catch(error => console.error('Error fetching taxonomies:', error));
  
    // 一覧表示されているCanvasから画像のサイズを取得してモーダルのCanvasに適用
    setTimeout(() => {

      const sourceCanvas = document.getElementById(`canvas-Image-${index}`);
      if (sourceCanvas) {
        const modalCanvas = document.getElementById('modal-canvas');
        const context = modalCanvas.getContext('2d');
        
        // 元の画像のサイズにCanvasのサイズを設定
        modalCanvas.width = sourceCanvas.width;
        modalCanvas.height = sourceCanvas.height;

        modalCanvas.addEventListener('contextmenu', (e) => e.preventDefault());
        context.drawImage(sourceCanvas, 0, 0, modalCanvas.width, modalCanvas.height);
      } else {
        const modalCanvas = document.getElementById('modal-canvas');
        const context = modalCanvas.getContext('2d');
        
        const cimage = new Image();

        cimage.onload = () => {
          // 元の画像のサイズをCanvasのサイズに設定
          modalCanvas.width = cimage.width;
          modalCanvas.height = cimage.height;
        context.drawImage(cimage, 0, 0, modalCanvas.width, modalCanvas.height);

        };
        cimage.src = image.url;

        modalCanvas.addEventListener('contextmenu', (e) => e.preventDefault());
      }
    }, 0);

    const handlePopState = () => {
        closeModal();
    };

    // `popstate`イベントリスナーを登録
    window.addEventListener('popstate', handlePopState);

    // コンポーネントがアンマウントされる時にイベントリスナーを削除
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  };

  const closeModal = useCallback(() => {
    if(isFav) {
      navigate('/fav/');
    } else {
      navigate('/');
    }
    setSelectedImage(null);
    setTaxonomies([]);
    onClose();
    setCurrentImageIndex(null);
  }, [navigate,onClose,isFav]);

  const showNextImage = () => {
    if (currentImageIndex !== null && currentImageIndex < images.length - 1) {
      const nextImage = images[currentImageIndex + 1];
      openModal(nextImage, currentImageIndex + 1);
    }
  };
  
  const showPreviousImage = () => {

    if (currentImageIndex !== null && currentImageIndex > 0) {
      const prevImage = images[currentImageIndex - 1];
      openModal(prevImage, currentImageIndex - 1);
    }
  };
  const handleAgree = () => {
    localStorage.setItem('userAgreed', 'true');
    setHasAgreed(true);
  };

  const handleDisagree = () => {
    window.location.href = 'https://www.google.com';
  };
  if (isFirstVisit) {
    return <div>Loading...</div>;
  }

  if (!hasAgreed) {
    return (
      <Modal isOpen={true} onClose={() => {}} isCentered >
        <ModalOverlay />
        <ModalContent width={{ base : "calc(100vw - 2rem)" ,md : "" }}>
          <ModalHeader textAlign="center" pb="0">
            <img className="logo-modal" src="/logo.png" alt=""/>
          </ModalHeader>
          <ModalBody>
            <p>このサイトは性的な内容、生成AIによる生成物を含みます。同意できる方のみ、アクセスしてください。<br /><br />このサイトを利用したことで生じる不利益について、一切責任を負いません。アクセスした時点で上記に同意したとみなします。</p>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button colorScheme="blue" mr={3} onClick={handleAgree}>同意してアクセス</Button>
            <Button variant="ghost" onClick={handleDisagree}>去る</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <ChakraProvider>
      <VStack
        spacing={2}
        align="start"
        position="fixed"
        top={{ base : "1rem" ,md : "2rem" }}
        right={{ base : "1rem" ,md : "2rem" }}
        zIndex="99"
        bgColor="#1A202CCC"
        p={{ base : "0.5rem" ,md : "1rem" }}
        borderRadius="lg"
      >
        <Link to="/">
          <IconButton
            icon={<FiHome />}
            aria-label="ホーム"
          />
        </Link>
        <IconButton
          icon={<FiBell />}
          aria-label="トピックス"
          onClick={drawerDisclosure.onOpen}
        />

        <Link to="/fav/" onClick={() => setHasMoreImages(true)}>
          <IconButton
            icon={<FiHeart />}
            aria-label="お気に入り"
          />
        </Link>
      </VStack>
      <Flex>
        <div w="100%">
          <Box 
            position={{ base : "fixed" ,md : "sticky" }}
            top={{ base : "auto" ,md : "0" }}
            bottom={{ base : "0" ,md : "auto" }}
            left="0" p={{ base : "1rem" ,md : "1rem 0 1rem 1rem" }}
            bgColor={{ base : "#1A202C" ,md : "" }}
            width={{ base : "100%" ,md : "" }}
            zIndex="2"
            overflowY={{ base : "scroll" ,md : "inherit" }}
            maxHeight="100%"
          >
            <Link to="/" onClick={() => setHasMoreImages(true)}>
              <img className="logo" src="/logo.png" alt=""/>
            </Link>



            <form onSubmit={handleSearchSubmit}>
              <Input type="text" value={currentSearchQuery} onChange={handleInputChange} ref={inputRef} placeholder="タグを入力" mt="10px"/>
              <Button colorScheme="blue" type="submit" width="100%" color="#4b4f56" mt="10px">Search</Button>
            </form>
            <FormControl display="flex" alignItems="center" mt="10px">
              <FormLabel htmlFor="nsfw-toggle" mb="0">
                R-18(NSFW)
              </FormLabel>
              <Switch id="nsfw-toggle" isChecked={isNsfw} onChange={handleNsfwToggle} />
            </FormControl>
            {/* サジェストされたタームの一覧 */}
            <VStack
              spacing={2}
              align='start'
              mt="10px"
            >
              {suggestedTerms.map(term => (
                <Tag key={term.id} onClick={() => handleTermClick(term)}>
                  {term.name}
                </Tag>
              ))}
            </VStack>
            {/* その他の要素 */}
          </Box>

        </div>
        <Box w="100%" p={{ base : "1rem 1rem 166px" ,md : "1rem" }}>
          <ResponsiveMasonry
            columnsCountBreakPoints={{350: 2, 750: 3, 900: 4}}
          >
            <Masonry gutter="10px">
              {images.map((image, index) => (
                <Box borderRadius="lg" overflow="hidden" cursor="pointer" position="relative" key={image.id}>
                  <div key={image.id} onClick={() => openModal(image, index)} style={{animation: "fadein 0.5s"}}>
                    <CanvasImage src={image.url} alt={`Image-${index}`} />
                    <IconButton
                      position="absolute"
                      bottom="0.5rem"
                      right="0.5rem"
                      aria-label="Like image"
                      icon={isLiked(image) ? <FaHeart color="#ff5353" /> : <FaRegHeart />}
                      onClick={(e) => {
                        e.stopPropagation(); // モーダルの開かないようにする
                        handleLikeButton(image);
                      }}
                    />
                  </div>
                </Box>
              ))}
            </Masonry>
        </ResponsiveMasonry>
        </Box>
      </Flex>

      <Drawer isOpen={drawerDisclosure.isOpen} placement="left" onClose={drawerDisclosure.onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>TOPICS</DrawerHeader>
          <DrawerBody>
          <Stack divider={<StackDivider />} spacing='4'>
            <List spacing={3}>
              {posts.map((post, index) => (
                <ListItem key={index}>
                  <Box>
                    <Text fontSize="sm">{new Date(post.date).toLocaleDateString()}</Text>
                    {post.acf && post.acf.cf_topic_link ? (
                      <a 
                        href={post.acf.cf_topic_link}
                        target={isExternalLink(post.acf.cf_topic_link) ? "_blank" : "_self"}
                        rel="noopener noreferrer"
                        style={{textDecoration: "underline"}}
                      >
                        {post.title.rendered}
                      </a>
                    ) : (
                      <span>{post.title.rendered}</span>
                    )}
                  </Box>
                </ListItem>
              ))}
            </List>
            <Card>
              <CardHeader pl="0">
                <Heading size='md'>ABOUT</Heading>
              </CardHeader>

              <CardBody pl="0">
                <Stack divider={<StackDivider />} spacing='4'>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      サイトの目的
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                      管理者が趣味で生成した画像を無造作に放り込む場所です。
                    </Text>
                  </Box>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      展望
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                      投稿機能を設置して、完全匿名の生成画像投稿サイトにする予定です。特に何かをアピールしたいわけでもないが、どこかに保管しておきたい人向け。
                    </Text>
                  </Box>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      注意
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                      このサイトに投稿されている画像はすべてAI生成物です。このサイトを閲覧することによって生じた不利益について、管理者は一切関知しません。尚、特定の絵の模倣と思われる、類似した出力を見つけた場合の問い合わせ先は後ほど記載予定です。
                    </Text>
                  </Box>
                </Stack>
              </CardBody>
            </Card>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Modal isOpen={selectedImage !== null} onClose={closeModal} size="xl">
        <ModalOverlay />
        <ModalContent p='0' overflow="hidden">
          <ModalCloseButton />
          <ModalBody p='0' display= {{ base : "block" ,md : "flex" }} >
          <Box maxWidth={{ base : "100%" ,md : "60%" }} pt={{ base : "4.6rem" ,md : "0rem" }}>
            <canvas
              id="modal-canvas"
              style={{
                maxHeight: '110vh',
                width: '100%',
                height: 'auto',
                objectFit: 'scale-down'
              }}
            ></canvas>
          </Box>

          <Box p='1rem 0' maxWidth={{ base : "100%" ,md : "40%" }}>
            <Box position={{ base : "absolute" ,md : "static" }} left={{ base : "1rem" ,md : "auto" }} top={{ base : "1rem" ,md : "auto" }}>
            <IconButton
              aria-label="Like image"
              icon={isLiked(selectedImage) ? <FaHeart color="#ff5353" /> : <FaRegHeart />}
              onClick={() => handleLikeButton(selectedImage)}
              ml={{ base : "" ,md : "1rem" }}
              bgColor={{ base : "#1A202CCC" ,md : "" }}
            />
            <Button onClick={showPreviousImage} disabled={currentImageIndex === 1} m="0 1rem" bgColor={{ base : "#1A202CCC" ,md : "" }}>前へ</Button>
            <Button onClick={showNextImage} disabled={currentImageIndex === images.length} bgColor={{ base : "#1A202CCC" ,md : "" }}>次へ</Button>

            </Box>

            <Wrap spacing={4} p='1rem'>
              {taxonomies.map(tax => (
                <Tag key={tax.id} cursor="pointer" onClick={() => handleTaxonomyClick(tax)}>
                  {tax.name}
                </Tag>
              ))}
            </Wrap>
          </Box>

          </ModalBody>

        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}

function CanvasImage({ src, alt }) {
  useEffect(() => {
    const canvas = document.getElementById(`canvas-${alt}`);
    const context = canvas.getContext('2d');
    const image = new Image();

    image.onload = () => {
      // 元の画像のサイズをCanvasのサイズに設定
      canvas.width = image.width;
      canvas.height = image.height;

      context.drawImage(image, 0, 0, canvas.width, canvas.height);
    };

    image.src = src;
    canvas.addEventListener('contextmenu', (e) => e.preventDefault());
  }, [src, alt]);

  return <canvas id={`canvas-${alt}`} style={{ maxWidth: '100%', height: 'auto' }}></canvas>;
}

export default ImageGallery;
